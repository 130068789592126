
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: 'b00daeb9bc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="variants">\n		<!-- Fixed/Dynamic Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<!--BACK TO ORDERS-->\n				<div class="left">\n					<a href="/variants/">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md"></span>\n					</a>\n				</div>\n				<div class="title">Modifica variante</div>\n\n			</div>\n		</div>\n\n		<!-- Scrollable page content-->\n		<div class="page-content">\n\n			<div class="card card-outline">\n		\n\n				<div class="list inline-labels no-hairlines-md" style="margin-top: 10px;">\n					<ul>\n\n						<!-- NOME -->\n\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold;">Nome</div>\n								<div class="item-input-wrap">\n									<input ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += c(ctx_2.item.name, ctx_2);
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' type="text" id="ingredient-name"/>\n									<span class="input-clear-button"></span>\n								</div>\n							</div>\n						</li>\n\n						<!-- DESCRIZIONE -->\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold;">Descrizione</div>\n								<div class="item-input-wrap">\n									<input type="text" ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += c(ctx_2.item.description, ctx_2);
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' id="ingredient-description"/>\n								</div>\n							</div>\n						</li>\n\n						<!-- AGGIUNTA SU PREZZO -->\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold; min-width:50%;">Incremento prezzo</div>\n								<div class="item-input-wrap">\n									<input type="number" ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += c(ctx_2.item.add_price, ctx_2);
          r += '"';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'value=0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' id="add-price"/>\n								</div>\n							</div>\n						</li>\n\n						\n						<!-- STORNO SU PREZZO -->\n						<!--\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold; min-width:50%;">Decremento prezzo</div>\n								<div class="item-input-wrap">\n									<input type="number" ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += c(ctx_2.item.remove_price, ctx_2);
          r += '"';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += 'value=0';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' id="remove-price"/>\n								</div>\n							</div>\n						</li>\n						-->\n					</ul>\n				</div>\n\n\n			</div>\n\n			<!-- Azioni variante -->\n\n			<div class="card card-outline">\n				<div class="card-content card-content-padding">\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='edit'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="row padding">\n						<button id="save-ingredient" class="col button button-small button-fill" style="min-height: 50px;">SALVA</button>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='new'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="row padding">\n						<button id="add-ingredient" class="col button button-small button-fill" style="min-height: 50px;">SALVA</button>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n\n			';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='edit'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="card card-outline">\n				<div class="card-content card-content-padding">\n					<div class="row padding">\n						<button\n										class="col button button-small button-fill sheet-open"\n										style="min-height: 50px;"\n										data-sheet=".cancel-confirm-sheet"\n						>CANCELLA VARIANTE</button>\n					</div>\n				</div>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n			<!-- Swipe to close sheet -->\n			<div class="sheet-modal cancel-confirm-sheet" style="height:auto; --f7-sheet-bg-color: #fff;">\n				<div class="sheet-modal-inner">\n					<div class="page-content">\n						<div class="block-title block-title-large">Attenzione!</div>\n						<div class="block">\n							<p><b>Sei sicuro di voler eliminare la variante?</b></p>\n							<a href="/menu/">\n								<div class="row padding">\n									<button id="cancel-ingredient-confirm" class="col button button-small button-fill" style="min-height: 50px;">CONFERMA</button>\n								</div>\n							</a>\n\n							<div class="row padding">\n								<button id="cancel-stop" class="col button button-small button-fill" style="min-height: 50px;">ANNULLA</button>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n\n		</div>\n	</div>\n\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    