import $$ from 'dom7';

import NotFoundPage from '../pages/404.f7.html';

import HelpPage from '../pages/help.f7.html';
import AppInfoPage from '../pages/app-info.f7.html';
import TermsPage from '../pages/terms.f7.html';
import LoginPage from '../pages/login.f7.html';
import WaitingOrdersPage from '../pages/waiting-orders.f7.html';
import AcceptedOrdersPage from '../pages/accepted-orders.f7.html';
import OrderPage from '../pages/order.f7.html';
import MenuPage from '../pages/menu.f7.html';
import ItemPage from '../pages/item.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import ItemsPage from '../pages/items.f7.html';
import VariantsPage from '../pages/variants.f7.html';
import VariantPage from '../pages/variant.f7.html';
import AppCore from './app-core';

var routes = 
[
	/*
	{
		path: '(.*)',
		component: NotFoundPage,
	},
	*/

	// index.html
	{
		path: '/index.html',
		component: LoginPage,
		on:
		{
			pageInit: function (event, page) 
			{
				console.log('routes /index (login)');

				let _user_logged = appUtilities.user_is_logged();

				if(_user_logged == true)
				{
					f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);
				}
			}
		}
	},

	// /
	{
		path: '/',
		component: LoginPage,
		on: 
		{
			pageInit: function (event, page) 
			{
				console.log('routes / (login)');

				let _user_logged = appUtilities.user_is_logged();

				if(_user_logged == true)
				{
					f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);
				}
			}
		}
	},

	// /help/
	{
		path: '/help/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			console.log('router /help');

			var router = this;
			var app = router.app;

			var param = routeTo.params.param;

			var _request_params = 
			{
				'session_token' : appCore.session_token,
			};

			//app.dialog.preloader(); //Preloader

			app.request(
			{
				url: api_url + 'customer_help.php',
				dataType: 'json',
				data: _request_params,
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (data) 
				{
					//app.dialog.close(); //close preloader

					console.log('help: ', data);

					resolve(
					{
						component: HelpPage,
					}, 
					{
						context: 
						{
							data: data,
						}
					});
				},
				error: function () 
				{
					//app.dialog.close();

					app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
					
					reject();
				}
			});
		}
	},

	// /app-info/
	{
		path: '/app-info/',
		component: AppInfoPage,
	},

	// /terms/
	{
		path: '/terms/',
		component: TermsPage,
	},

	// /login/
	{
		path: '/login/',
		component: LoginPage
	},

	// /profile/
	{
		path: '/profile/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			console.log('routes /profile ');

			var router = this;
			var app = router.app;

			let _user_logged = appUtilities.user_is_logged();

			if(_user_logged == false)
			{
				return false;
			}

			let _periodFrom = routeTo.query.periodFrom;
			let _periodTo = routeTo.query.periodTo;

			let _default_period_from = moment().clone().startOf('month').format('YYYY-MM-DD');
			let _default_period_to = moment().clone().endOf('month').format('YYYY-MM-DD');

			_periodFrom = (typeof(_periodFrom) == 'undefined') ? _default_period_from : _periodFrom;
			_periodTo = (typeof(_periodTo) == 'undefined') ? _default_period_to : _periodTo;

			var _request_params =
			{
				'session_token' : appCore.session_token,
			};

			app.request(
			{
				url: api_url + 'restaurant_profile.php',
				dataType: 'json',
				data: _request_params,
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (data) 
				{
					console.log('response profile: ', data);

					appCore.restaurant_data = data.data;

					_request_params = 
					{
						'session_token' : appCore.session_token,
						'period_from' : _periodFrom,
						'period_to' : _periodTo
					};

					app.request(
						{
							url: api_url + 'restaurant_period_orders.php',
							dataType: 'json',
							data: _request_params,
							method: 'POST',
							cache: false,
							crossDomain: true,
							success: function (data) 
							{
								console.log('response period orders: ', data);
								
								resolve(
									{
										component: ProfilePage,
									},
									{
										context: (function () 
										{
											return {
												profile: appCore.restaurant_data,
												data: data.data,
												period_from: _periodFrom,
												period_to: _periodTo
											}
										})()
									}
								);
							},
							error: function () 
							{
								app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
								
								reject();
							}
						}
					);
				},
				error: function () 
				{
					app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
					
					reject();
				}
			});
		}
	},

	// /waiting-orders/
	{
		path: '/waiting-orders/',
		component: WaitingOrdersPage,
	},

	// /accepted-orders/
	{
		path: '/accepted-orders/',
		component: AcceptedOrdersPage,
	},

	// /order-details/
	{
		path: '/order/',
		component: OrderPage,
	},

	// /menu/
	{
		path: '/menu/',
		component: MenuPage
	},

	// /menu-items/
	{
		path: '/items/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			let _user_logged = appUtilities.user_is_logged();

			if(_user_logged == false)
			{
				return false;
			}

			console.log('routes /items');

			var router = this;
			var app = router.app;

			var _request_params = 
			{
				'session_token' : appCore.session_token,
			};

			app.request(
			{
				url: api_url + 'restaurant_items.php',
				dataType: 'json',
				data: _request_params,
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (data) 
				{
					console.log("restaurant items data", data);

					appCore.items = data.data;

					appCore.clearCurrentEdit();

					resolve(
					{
						component: ItemsPage,
					},
					{
						context: 
						{
							data: data,
						}
					});

					f7app.tab.show('#view-menu', '#tab-menu', true);
				},
				error: function () 
				{
					app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
					
					reject();
				}
			});
		},
		
	},

	// /menu-variants/
	{
		path: '/variants/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			let _user_logged = appUtilities.user_is_logged();

			if(_user_logged == false)
			{	
				return false;
			}

			console.log('routes /variants');

			var router = this;
			var app = router.app;

			var _request_params = 
			{
				'session_token' : appCore.session_token,
			};

			app.request(
			{
				url: api_url + 'restaurant_ingredients.php',
				dataType: 'json',
				data: _request_params,
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (ingredients)
				{
					console.log("restaurant ingredients data", ingredients);

					appCore.ingredients = ingredients;

					appCore.clearCurrentEdit();

					resolve(
					{
						component: VariantsPage,
					},
					{
						context: 
						{
							data: ingredients.data,
						}
					});
				},
				error: function () 
				{
					app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
				}
			});
		}
	},

	// /item/
	{
		path: '/item/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			console.log('routes /item');

			var router = this;
			var app = router.app;
		
			// Type "edit" oppure "new"
			var _type = routeTo.query.type;
			var _item_id = routeTo.query.item_id;
			var _item_cat = routeTo.query.cat;
			var _item_subcat = routeTo.query.subcat;

			console.log('item: ', _type);
			
			if(_item_id != null){
				console.log('item: ', appCore.items[_item_cat].subcategory[_item_subcat]);

				appCore.current_edited_item = 
					appCore.items[_item_cat].
					subcategory[_item_subcat].
					items.find((item)=>{
						return item.item_id == _item_id
					})
				console.log('current_edited_item: ', appCore.current_edited_item);
			}

			app.request(
			{
				url: api_url + 'category_subcat.php',
				dataType: 'json',
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (data)
				{
					console.log('response cat_subcat: ', data);

					appCore.cat_subcat = data.data;
					resolve(
					{
						component: ItemPage,
					},
					{
						context: (function () {
							if(_item_id != null && typeof(_item_id) != 'undefined'){
								return {
									// Type "edit" oppure "new"
									type: _type,
									data: data.data,
									category: appCore.items[_item_cat].name,
									subcategory: appCore.items[_item_cat].subcategory[_item_subcat].name,
									item: appCore.current_edited_item,
									is_customizable: (appCore.current_edited_item.is_customizable != "0"),
									visible_for_delivery: (appCore.current_edited_item.visible_for_delivery != "0"),
									visible_for_menu: (appCore.current_edited_item.visible_for_menu != "0")
								}
							}
							else{
								return {
									// Type "edit" oppure "new"
									type: _type,
									data: data.data
								}
							}
						})()
					});

					$$('.toolbar').hide();
				},
				error: function () 
				{
					app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
					
					reject();
				}
			});
		}
	},

	// /variant/
	{
		path: '/variant/',
		async: function (routeTo, routeFrom, resolve, reject) 
		{
			console.log('routes /variant');
		
			// Type "edit" oppure "new"

			console.log("route to", routeTo);

			var _type = routeTo.query.type;
			var _id = routeTo.query.id;

			console.log('ingredient => type:', _type + " id: " + _id);

			console.log('variants', appCore.ingredients);

			if(_id != null)
			{
				appCore.current_edited_item =
					appCore.
					ingredients.
					data.
					find(ingredient => ingredient.id == _id)
			}

			resolve(
			{
				component: VariantPage,
			},
			{
				context: (function () 
				{
					// Type "edit" oppure "new"

					if(_id != null && typeof(_id) != 'undefined')
					{
						console.log("current edit variant", appCore.current_edited_item);

						return {
							// Type "edit" oppure "new"
							type: _type,
							item: appCore.current_edited_item
						}
					}
					else
					{
						return {
							type: _type
						}
					}
				})
			});

			$$('.toolbar').hide();
		}
	}
];

export default routes;