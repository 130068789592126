
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      f7app.toolbar.show('.toolbar', false);
      console.log('routes /order/');
      var _order_id = _route.query.order_id;
      appCore.order_id = _order_id;
      var _request_params = {
        'order_id': _order_id,
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'get_order_details.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (orderDetailsData) {
          console.log('order details data', orderDetailsData);

          _self.$setState({
            data: orderDetailsData.data
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    }
  },
  id: 'ceb1c63e53',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="order">\n\n		<!-- Fixed/Dynamic Navbar -->\n\n		<div class="navbar">\n\n			<div class="navbar-bg"></div>\n\n			<div class="navbar-inner sliding">\n\n				<!--BACK TO ORDERS-->\n\n				';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n				<div class="left">\n				\n					<a href="#">\n				\n						<i class="icon icon-back"></i>\n						<span class="if-not-md"></span>\n				\n					</a>\n				\n				</div>\n				\n				<div class="title">Dettagli ordine</div>\n				\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n				';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n				<div class="left">\n\n					<a	';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'href="/waiting-orders/"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status != 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'href="/accepted-orders/"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					>\n\n						<i class="icon icon-back"></i>\n						<span class="if-not-md"></span>\n\n					</a>\n\n				</div>\n\n				<div class="title">\n					\n					Dettagli ordine ';
          r += c(ctx_2.data.order_number, ctx_2);
          r += '\n				\n				</div>\n\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n			</div>\n\n		</div>\n\n		<!-- Scrollable page content-->\n\n		<div class="page-content">\n\n			';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n			<!-- Pagina caricamento -->\n\n			<div class="list media-list">\n			  <ul>\n				<li class="item-content skeleton-text skeleton-effect-wave">\n				  <div class="item-media">\n					<div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>\n				  </div>\n				  <div class="item-inner">\n					<div class="item-title-row">\n					  <div class="item-title">Title</div>\n					</div>\n					<div class="item-subtitle">Subtitle</div>\n					<div class="item-text">\n					  Placeholder text line 1<br />\n					  Text line 2\n					</div>\n				  </div>\n				</li>\n				<li class="item-content skeleton-text skeleton-effect-wave">\n				  <div class="item-media">\n					<div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>\n				  </div>\n				  <div class="item-inner">\n					<div class="item-title-row">\n					  <div class="item-title">Title</div>\n					</div>\n					<div class="item-subtitle">Subtitle</div>\n					<div class="item-text">\n					  Placeholder text line 1<br />\n					  Text line 2\n					</div>\n				  </div>\n				</li>\n			  </ul>\n			</div>\n			\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n			';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n\n			<div class="card card-outline">\n			\n				<!-- Card body -->\n\n				<div class="card-content card-content-padding">\n\n					<div class="list media-list">\n\n						<ul>\n							\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n							<!-- Posizione di consegna -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">\n												\n												Posizione di consegna\n											\n											</div>\n\n										</div>\n\n										<div class="item-subtitle" style="white-space:pre-wrap;">';
              r += c(ctx_3.data.order_address, ctx_3);
              r += ' [';
              r += c(ctx_3.data.distance, ctx_3);
              r += ' Km]</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n							<!-- Dettagli cliente -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Dettagli cliente</div>\n\n										</div>\n\n										<div class="item-subtitle">\n											\n											Nome: ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n										\n											';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status == 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n											- ';
              r += c(ctx_3.data.phone_number, ctx_3);
              r += '\n											\n											';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n						</ul>\n\n					</div>\n				\n					<!-- Dettaglio ordine -->\n					\n					<div class="list media-list" style="margin-top: 15px;">\n\n						<ul>\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Dettaglio ordine</div>\n\n										</div>\n\n								</div>\n\n							</li>\n\n						</ul>\n						\n					</div>\n\n					<div class="list media-list">\n\n						<ul>\n			\n							';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.item_details, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			\n							<li>\n			\n								<div class="item-content">\n				\n									<div class="item-inner">\n				\n									<div class="item-title-row">\n				\n										<div class="item-title" style="height: auto; white-space: pre-wrap;">';
              r += c(ctx_3.item_qty, ctx_3);
              r += ' x ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n				\n										<div class="item-after">';
              r += c(ctx_3.item_total, ctx_3);
              r += '&euro;</div>\n				\n									</div>\n				\n									<div class="item-subtitle" style="margin-left: 10px; font-style: italic; height: auto; white-space: pre-wrap;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.ingredients_description, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>\n				\n									<div class="item-text"></div>\n				\n									</div>\n				\n								</div>\n			\n							</li>\n			\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n						</ul>\n			\n					</div>\n\n					<div class="list media-list" style="margin-top: 15px;">\n\n						<ul>\n\n							<!-- Note ordine -->\n\n							<li>\n\n								<div class="item-content" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.instruction !== ''", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' style="background-color: #fff389;" ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Note ordine</div>\n\n										</div>\n\n										<div style="height: auto; white-space: pre-wrap;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.instruction, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							<!-- Totali ordine -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">\n												\n												Totali\n											\n											</div>\n\n										</div>\n\n										<div class="item-row">\n\n											<div class="item-subtitle">\n												\n												Totale prodotti:\n											\n											</div>\n\n											<div class="item-after">\n												\n												';
          r += c(ctx_2.data.item_total, ctx_2);
          r += '&euro;\n											\n											</div>\n\n										</div>\n\n										<div class="item-row">\n\n											<div class="item-subtitle">\n												\n												Costo spedizione:\n											\n											</div>\n\n											<div class="item-after">\n												\n												';
          r += c(ctx_2.data.shipping_cost, ctx_2);
          r += '&euro;\n											\n											</div>\n											\n										</div>\n\n										<div class="item-row">\n\n											<div class="item-subtitle">\n												\n												Totale complessivo:\n											\n											</div>\n\n											<div class="item-after">\n												\n												';
          r += c(ctx_2.data.grand_total, ctx_2);
          r += '&euro;\n											\n											</div>\n											\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							<!-- Metodo di pagamento -->\n\n							<li style="background-color: ';
          r += c(ctx_2.data.bg_color, ctx_2);
          r += ';">\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Metodo di pagamento</div>\n\n										</div>\n\n										<div class="item-subtitle">\n\n										';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.payment_type_code == 'credit_card'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n											Carta di credito\n\n										';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n											Contanti alla consegna\n\n										';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n							\n							<!-- Orario di inserimento -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Giorno di consegna:</div>\n\n										</div>\n\n										<div class="item-subtitle">\n\n											';
          r += Template7Helpers.js.call(ctx_2, "appUtilities.convertUTCToLocalDate(this.data.order_date).format('DD/MM/YYYY')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							<!-- Orario di consegna -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Orario di consegna</div>\n\n										</div>\n\n										<div class="item-subtitle">\n\n										';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.time_limit_status == 'pre_order'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n											';
              r += c(ctx_3.data.time_limit, ctx_3);
              r += '\n\n										';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					\n										';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.time_limit_status != 'pre_order'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n											Il prima possibile\n\n										';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							<!-- Indirizzo di consegna -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Indirizzo di consegna</div>\n\n										</div>\n\n										<div class="item-subtitle">\n\n											';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.order_address, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status==0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n							<!-- Selezione orario di ritiro -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Orario ritiro rider</div>\n\n										</div>\n\n										<div class="item-subtitle">\n											\n											<input id="driver-collect-time" type="time" placeholder="--:--" style="border: 1px solid #b11733;" required>\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n							<!-- Chiama cliente -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<a href="tel:';
          r += c(ctx_2.data.phone_number, ctx_2);
          r += '" class="external col button button-fill" style="min-height: 30px; min-width: 100%;">Chiama cliente</a>\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status != 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n							<!-- Visualizzazione orario di ritiro -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<div class="item-title" style="font-weight: bold;">Orario ritiro rider</div>\n\n										</div>\n\n										<div class="item-subtitle">\n\n											';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.data.restaurant_time_limit).format('HH:mm')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
              r += Template7Helpers.js_if.call(ctx_3, "this.data.driver_details !== null", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' \n\n							<!-- Chiama rider -->\n\n							<li>\n\n								<div class="item-content">\n\n									<div class="item-inner">\n\n										<div class="item-title-row">\n\n											<a href="tel:';
                  r += c(ctx_4.data.driver_details.phone, ctx_4);
                  r += '" class="external col button button-fill" style="min-height: 30px; min-width: 100%;">Chiama rider</a>\n\n										</div>\n\n									</div>\n\n								</div>\n\n							</li>\n\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n\n							';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n						</ul>\n\n					</div>\n\n				</div>\n\n			</div>\n\n			<!--Ora ritiro e accetta/rifiuta-->\n\n			<div class="card card-outline">\n			\n				<!--ORDINE DA GESTIRE-->\n\n				<!-- Card header -->\n\n				';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.status==0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n\n				<!-- Azioni ordine -->\n\n				<div class="card-content card-content-padding">\n\n					<div class="row">\n\n						<button id="reject" class="col button button-small" style="min-height: 50px; background-color: #B11733; color: #FFF;">RIFIUTA</button>\n\n						<button id="accept" class="col button button-small" style="min-height: 50px; background-color: #43db43; color: #000;">ACCETTA</button>\n\n					</div>\n\n				</div>\n\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n\n			</div>\n\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n		</div>\n\n	</div>\n\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    