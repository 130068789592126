
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '8d7bc4c42c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=items><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><!--BACK TO ORDERS--><div class=left><a href=/menu/ ><i class="icon icon-back"></i> <span class=if-not-md></span></a></div><div class=title>Elenco prodotti</div><div class=right><a class=link href=/help/ >Aiuto</a></div></div></div><!-- Floating Action Button --><div class="fab fab-right-bottom"><a href="/item/?type=new"><i class="icon f7-icons">plus</i></a></div><!-- Scrollable page content--><div class=page-content><div class=card><div class="card-content card-content-padding"><div class="list accordion-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=accordion-item><a href=# class="item-content item-link"><div class=item-inner><div class=item-title style="font-weight: bold;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></a><div class=accordion-item-content><div class=block><div class="list accordion-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.subcategory, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=accordion-item><a href=# class="item-content item-link"><div class=item-inner><div class=item-title style="font-weight: bold;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></a><div class=accordion-item-content><div class=block>';
              r += Template7Helpers.each.call(ctx_3, ctx_3.items, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="list media-list"><ul><li><a href="/item/?type=edit&item_id=';
                  r += c(ctx_4.item_id, ctx_4);
                  r += '&cat=';
                  r += c(data_2 && data_2.index, ctx_4);
                  r += '&subcat=';
                  r += c(data_3 && data_3.index, ctx_4);
                  r += '" class="item-link item-content"><div class="item item-content"><!--<div class="item-media"></div>--><div class=item-inner><div class=item-title-row><div class=row style="min-width: 100%;"><div class="item-title col-100 xsmall-100 large-90"><span data-item-id=';
                  r += c(ctx_4.item_id, ctx_4);
                  r += ' data-item-price style="font-weight: bold;">';
                  r += c(ctx_4.price, ctx_4);
                  r += '&euro; - </span><span data-item-id=';
                  r += c(ctx_4.item_id, ctx_4);
                  r += ' data-item-name style="margin-left: 2px; height: auto; white-space: pre-wrap; font-weight: bold;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.name, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span><div class=item-subtitle style="height: auto; white-space: pre-wrap;">';
                  r += Template7Helpers.escape.call(ctx_4, ctx_4.description, {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div><div class=item-text></div></div></div></div></div></div></a></li></ul></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div><div id=white-space-menu class=item-content style="height: 50px;"><div class=item-inner><div class=item-title style="font-weight: bold;"></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    