import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';

// Import Moment.js
import * as moment from 'moment';
import 'moment/locale/it';

// Import main app component
import App from '../app.f7.html';

import AppCore from './app-core.js';
import AppUtilities from './app-utilities.js';

window.app_type = 'restaurant';
window.app_version = '1.0.14.2';
window.debug_enabled = true;
window.device_type = '';

window.app_url = '';
window.push_state_root = '';

//window.api_url = 'https://api.jacknb.com/';
window.api_url = 'https://dev.api.jacknb.com/';
//window.api_url = 'http://localhost/api_jacknb/';

window.general_error_message = "A causa della connessione a internet limitata le prestazioni dell'app potrebbero essere ridotte";

console.log('App version: ' + app_version);

if(debug_enabled == false)
{
  var _console = {};

  window.console = _console;

  console.log = function(){};
  console.info = function(){};
  console.warn = function(){};
  console.error = function(){};
}

window.appCore = new AppCore();
window.appUtilities = new AppUtilities();
window.moment = moment;

window.ViewMain = null;
window.ViewLogin = null;
window.ViewWaitingOrders = null;
window.ViewAcceptedOrders = null;
window.ViewMenu = null;
window.ViewProfile = null;

window.f7app = new Framework7(
{
	root: '#app', // App root element
	component: App, // App main component
	id: 'com.jacknb.restaurant', // App bundle ID
	name: 'jacknb_restaurant', // App name
	theme: 'auto', // Automatic theme detection
	cache: false,
	cacheDuration: 0,

	// App routes
	routes: routes,

	// Register service worker
	serviceWorker: Framework7.device.cordova ? {} : 
	{
		path: '/service-worker.js',
	},
	// Input settings
	input: 
	{
		scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
		scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
	},
	// Cordova Statusbar settings
	statusbar: 
	{
		iosOverlaysWebView: true,
		androidOverlaysWebView: false,
	},
	on: 
	{
		init: ()=> 
		{
			var f7 = this;

			if (typeof f7 !== 'undefined' && f7.device.cordova) 
			{
				// Init cordova APIs (see cordova-app.js)

				cordovaApp.init(f7);
			}

			ViewMain = f7app.views.create('.view-main',
			{
				url: 'index.html',
				pushState: true,
				pushStateRoot: document.location.pathname.split('index.html')[0],
				animate : false,
				on: 
				{
					init : function () 
					{
						var f7 = this;
			
						// Set push state root
			
						push_state_root = document.location.pathname.split('index.html')[0];
			
						console.log('Push state root: ' + push_state_root);
			
						// Set app url
						
						app_url = document.location.origin + push_state_root;
			
						console.log('App url: ' + app_url);
			
						// Recupera i dati del dispositivo

						device_type = get_device_type();

						// Verifica se ci sono aggiornamenti disponibili

						get_current_app_version();

						// Prosegue col caricamento dell'applicazione

						on_app_load(f7);
					}
				}
			});
		}
	}
});

// --- General APP functions ---

function on_app_load(f7)
{
	let _user_logged = appUtilities.user_is_logged();

	if(_user_logged == false)
	{
	  return;
	}
  
	appCore.session_token = appUtilities.getParam('session-token');
  
	// Verifica se il token di sessione è valido
  
	var _request_params = 
	{
	  'token' : appCore.session_token
	};
  
	f7app.request(
	{
	  url: api_url + 'verify_token.php',
	  dataType: 'json',
	  data: _request_params,
	  method: 'POST',
	  cache: false,
	  crossDomain: true,
	  success: function (data) 
	  {
		console.log("verify token", data);
  
		let _success = parseFloat(data.success);
  
		if(_success == false)
		{
		  // Effettua il logout
  
		  log_out();
		}
		else
		{
			send_app_and_device_data();

			f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);

			var _request_params = 
			{
				'session_token' : appCore.session_token,
			};
		
			f7app.request(
			{
				url: api_url + 'restaurant_orders.php',
				dataType: 'json',
				data: _request_params,
				method: 'POST',
				cache: false,
				crossDomain: true,
				success: function (data)
				{
					console.log('on load waiting_orders: ', data);
				
					f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);
				},
				error: function () 
				{
					f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
					
					reject();
				}
			});
		}
	  },
	  error: function () 
	  {
		f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
	  }
	});
}

// --- General DOM ---

$$(document).on('DOMContentLoaded', function()
{
	ViewLogin = f7app.views.get('#view-login');
	ViewWaitingOrders = f7app.views.get('#view-waiting-orders');
	ViewAcceptedOrders = f7app.views.get('#view-accepted-orders');
	ViewMenu = f7app.views.get('#view-menu');
	ViewProfile = f7app.views.get('#view-profile');
});

$$(document).on('DOMNodeInserted', '.pac-container', function() 
{
  $$('.pac-item, .pac-item span').addClass('no-fastclick');
});

// --- Login and signup

$$(document).on('click', '#signin', function (e) 
{
  e.preventDefault();

  log_in();
});

$$(document).on('click', '#signup-save', function (e) 
{
  e.preventDefault();

  register();
});

$$(document).on('click', '#logout', function (e) 
{
  e.preventDefault();

  log_out();
});

$$(document).on('click', '#request-reset-password', function (e) 
{
  let _email = $$('#signin-email').val().trim();

  if(_email == "")
  {
    f7app.dialog.alert("Inserire l'indirizzo email!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
    'jacknb-user-action' : "request-reset-password",
    'email' : _email
  };

  f7app.request(
  {
    url: appCore.site_url,
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      if(data.success == true)
      {
        f7app.dialog.alert("Richiesta inviata con successo! A breve riceverai un'email per completare la reimpostazione della password.");
      }
      else
      {
        f7app.dialog.alert("Impossibile richiedere la reimpostazione della password! E' stato inserito un indirizzo email valido?");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});

function log_in()
{
	let _email = $$('#signin-email').val().trim();
	let _password = $$('#signin-password').val();

	if(_email == "")
	{
		f7app.dialog.alert("Inserire l'indirizzo email!");

		return;
	}

	let _email_isValid = appUtilities.validateEmail(_email);

	if(_email_isValid == false)
	{
		f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

		return;
	}

	if(_password == "")
	{
		f7app.dialog.alert("Inserire la password!");

		return;
	}

	let _request_params = 
	{
		'email' : _email,
		'password' : _password,
		'user_type' : 'Restaurant'
	};

	f7app.request(
	{
		url: api_url + 'login.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('login: ', data);

			let _success = parseFloat(data.success);

			if(_success == 1)
			{
				let _sessionToken = data.data.session_token;

				appUtilities.setParam('session-token', _sessionToken);
		
				appCore.session_token = _sessionToken;
		
				window.location.href = app_url;
			}
			else
			{
				f7app.dialog.alert("Errore durante il login!");
			}
		},
		error: function (error) 
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
		}
	});
}

function log_out()
{
	appUtilities.removeParam('session-token');

	window.location.href = app_url;
}

// --- Tab data reloading ---

$$(document).on('click', '#tab-waiting-orders', function(e)
{
	f7app.view.main.router.navigate('/waiting-orders/')
})

$$(document).on('click', '#tab-accepted-orders', function(e)
{
  f7app.view.main.router.navigate('/accepted-orders/')
})

$$(document).on('click', '#tab-menu', function(e)
{
  f7app.view.main.router.navigate('/menu/')
})

$$(document).on('click', '#tab-profile', function(e)
{
  f7app.view.main.router.navigate('/profile/')
})

// --- Order manage ---

function Refresh_Order_Views()
{
	ViewWaitingOrders.router.refreshPage();
	ViewAcceptedOrders.router.refreshPage();
}

$$(document).on('click', '#accept', function (e) 
{
	$$(this).prop({ disabled: true });

	let _driver_collect_time = $$('#driver-collect-time').val();

	if(_driver_collect_time.trim() == "")
	{
		f7app.dialog.alert('Selezionare orario di ritiro ordine!', "Attenzione");

		return false;
	}

	let _current_date = new Date();

	let _time_array = _driver_collect_time.split(":");

	let _restaurant_time_limit_local = new Date(
													_current_date.getFullYear(), 
													_current_date.getMonth(), 
													_current_date.getDate(), 
													("0" + _time_array[0]).slice(-2), 
													("0" + _time_array[1]).slice(-2)
												);

	let _restaurant_time_limit_utc = new moment(_restaurant_time_limit_local).utc();
	let _restaurant_time_limit_utc_string = _restaurant_time_limit_utc.format('YYYY-MM-DD HH:mm');

	console.log('restaurant time limit utc', _restaurant_time_limit_utc);
	console.log('restaurant time limit utc string', _restaurant_time_limit_utc_string);

	var _request_params = 
	{
		'session_token' : appCore.session_token,
		'order_id': appCore.order_id,
		'status': 1,
		'time_limit': _restaurant_time_limit_utc_string
	};

	console.log('order id: ', appCore.order_id);

	f7app.request(
	{
		url: api_url + 'restaurant_acceptReject_order.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log("accept order", data);

			ViewWaitingOrders.router.navigate("/waiting-orders/", 
			{
				ignoreCache: true,
				force: true
			});

			ViewAcceptedOrders.router.refreshPage();
			
			f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);
		},
		error: function () 
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});

	console.log('accept_order')
});

$$(document).on('click', '#reject', function (e) 
{
	f7app.dialog.confirm("Confermare il rifiuto dell'ordine?", "Continuare?", function () 
    {
		$$(this).prop({ disabled: true });

		var _request_params = 
		{
			'session_token' : appCore.session_token,
			'order_id': appCore.order_id,
			'status': 10,
			'time_limit': ''
		};
	
		console.log('order id: ', appCore.order_id);
	
		f7app.request(
		{
			url: api_url + 'restaurant_acceptReject_order.php',
			dataType: 'json',
			data: _request_params,
			method: 'POST',
			cache: false,
			crossDomain: true,
			success: function (data) 
			{
				console.log("reject order", data);
	
				ViewWaitingOrders.router.navigate("/waiting-orders/", 
				{
					ignoreCache: true,
					force: true
				});
	
				ViewAcceptedOrders.router.refreshPage();
				
				f7app.tab.show('#view-waiting-orders', '#tab-waiting-orders', true);
			},
			error: function () 
			{
				f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
			}
		});
	
		console.log('reject_order')
    });
});

setInterval(function()
{ 
	// === Controllo periodico ordini correnti ===

	if(appCore.waiting_orders_first_load == true)
	{
		return false;
	}

	var _request_params = 
	{
		'session_token' : appCore.session_token,
	};

	// Ordini in attesa di accettazione

	f7app.request(
	{
		url: api_url + 'restaurant_orders.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data)
		{
			let _current_view = f7app.views.current;
			let _current_page_name = ViewWaitingOrders.router.currentPageEl.getAttribute('data-name');

			if(data.data === null)
			{
				if(appCore.waiting_orders_count > 0)
				{
					appCore.waiting_orders_count = 0;

					console.log('current page name ' + _current_page_name);
					
					if(_current_page_name == 'waiting-orders')
					{
						console.log('refresh waiting-orders');

						Refresh_Order_Views();
					}
				}

				return false;
			}

			let _waiting_orders_count = data.data.length;

			if(appCore.waiting_orders_count != _waiting_orders_count)
			{
				console.log('response waiting-orders: ', data);
				
				appCore.waiting_orders_count = _waiting_orders_count;

				console.log('current page name ' + _current_page_name);

				if(_current_page_name == 'waiting-orders')
				{
					console.log('refresh waiting-orders');

					Refresh_Order_Views();
				}
			}
		},
		error: function () 
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});
},
10000);

// --- Menu manage ---

//image

$$(document).on('click', '#cat', function (e)
{
	e.preventDefault();

	// Se già aperto, rimuovo l'html del container (destroy su picker inline non funziona)
	if($$('#picker-cat-container').children().length >0){
		$$('#picker-cat-container').children().remove()
	}
	else
	{
		f7app.picker.create({
			containerEl: '#picker-cat-container',
			inputEl: '#picker-cat',
			toolbar: false,
			formatValue: function (values, displayValues) {
				return values[0];
			},
			cols: [
				{
					values: (function () {
						let arr = [];

						appCore.cat_subcat.forEach(cat => {
							arr.push(cat.name)
						});

						return arr;
					})(),
					onChange: (picker, category)=>{
						appCore.current_subcat = appCore.cat_subcat.find((cat)=>{return cat.name == category}).subcategory;
						if($$('#picker-subcat-container').children().length >0){
							$$('#picker-subcat-container').children().remove()
						}
						console.log('current_subcat: ', appCore.current_subcat)
					}
				}
			],
			updateValuesOnMomentum: true,
		});
	}
});

$$(document).on('click', '#subcat', function (e)
{
	e.preventDefault();

	// Se già aperto, rimuovo l'html del container (destroy su picker inline non va)
	if($$('#picker-subcat-container').children().length >0)
	{
		$$('#picker-subcat-container').children().remove()
	}
	else
	{
		if($$('#picker-cat-container').children().length >0)
		{
			$$('#picker-cat-container').children().remove()
		}

		f7app.picker.create({
			containerEl: '#picker-subcat-container',
			inputEl: '#picker-subcat',
			toolbar: false,
			formatValue: function (values, displayValues) {
			return values[0];
			},
			cols: [
			
			{
				values: (function () {
					let arr = [];

					appCore.current_subcat.forEach(subcat => {
						arr.push(subcat.name)
					});

					return arr;
				})(),
			}
			],
			updateValuesOnMomentum: true
		});
	}
});

$$(document).on('click', '#save-item', function (e) 
{
	e.preventDefault();

	let _item_name = $$('#item-name').val();
	let _item_description = $$('#item-description').val();
	let _category = $$('#picker-cat').val();
	let _subcategory = $$('#picker-subcat').val();
	let _price = $$('#item-price').val().replace(',', '.');
	let _is_customizable = $$('#is_customizable').prop('checked');
	let _visible_for_delivery = $$('#visible_for_delivery').prop('checked');
	let _visible_for_menu = $$('#visible_for_menu').prop('checked');
	let _image = $$('#item-image').prop('files');

	console.log('image el: ', _image)

	let _fd = new FormData()

	if(_image.length > 0)
	{
		_fd.append('uploadedfile', _image[0], _image[0].name)
	}

	if(
		_category == ""
		|| _subcategory == ""
		|| _item_name == ""
		|| _price == ""
		|| _price == 0.00
	)
	{
		f7app.dialog.alert('Compilare tutti i campi!', "Dati mancanti");

		return;
	}

	_fd.append('session_token', appCore.session_token);
	_fd.append('item_id', appCore.current_edited_item.item_id);
	_fd.append('name', _item_name);
	_fd.append('description', _item_description);
	_fd.append('price', _price);
	_fd.append('cat_id', appCore.cat_subcat.find( (cat)=>{ return cat.name == _category}).id);
	_fd.append('subcat_id', appCore.cat_subcat.find( (cat)=>{ return cat.name == _category}).subcategory.find( (subcat)=>{ return subcat.name == _subcategory}).id);
	_fd.append('is_customizable', _is_customizable);
	_fd.append('visible_for_delivery', _visible_for_delivery);
	_fd.append('visible_for_menu', _visible_for_menu);

	f7app.request(
	{
		url: api_url + 'edit_item.php',
		contentType:  'multipart/form-data',
		dataType: 'json',
		processData: true,
		data: _fd,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data)
		{
			console.log('saved item: ', data)

			if(data.success != 1)
			{
				f7app.dialog.alert('Errore di caricamento!', data);

				return
			}
			
			ViewMenu.router.navigate("/items/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});
});

$$(document).on('click', '#add-item', function (e) 
{
	e.preventDefault();

	console.log($$('#item-price'));

	let _item_name = $$('#item-name').val();
	let _item_description = $$('#item-description').val();
	let _category = $$('#picker-cat').val();
	let _subcategory = $$('#picker-subcat').val();
	let _price = $$('#item-price').val().replace(',', '.');
	let _is_customizable = $$('#is_customizable').prop('checked');
	let _visible_for_delivery = $$('#visible_for_delivery').prop('checked');
	let _visible_for_menu = $$('#visible_for_menu').prop('checked');
	let _image = $$('#item-image').prop('files');

	let _fd = new FormData()

	// Check image presense
	if(_image.length > 0)
	{
		_fd.append('uploadedfile', _image[0], _image[0].name)
	}

	if
	(
		_category == "" 
		|| _subcategory == "" 
		|| _item_name == "" 
		|| _price == "" 
		|| _price == 0.00
	)
	{
		f7app.dialog.alert('Compilare tutti i campi!', "Dati mancanti");

		return;
	}

	_fd.append('session_token', appCore.session_token);
	_fd.append('name', _item_name);
	_fd.append('description', _item_description);
	_fd.append('price', _price);
	_fd.append('cat_id', appCore.cat_subcat.find( (cat)=>{ return cat.name == _category}).id);
	_fd.append('subcat_id', appCore.cat_subcat.find( (cat)=>{ return cat.name == _category}).subcategory.find( (subcat)=>{ return subcat.name == _subcategory}).id);
	_fd.append('is_customizable', _is_customizable);
	_fd.append('visible_for_delivery', _visible_for_delivery);
	_fd.append('visible_for_menu', _visible_for_menu);

	f7app.request(
	{
		url: api_url + 'add_item.php',
		contentType:  'multipart/form-data',
		dataType: 'json',
		processData: true,
		data: _fd,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('added item: ', data)

			ViewMenu.router.navigate("/items/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});
});

$$(document).on('click', '#add-ingredient', function (e) 
{
	e.preventDefault();

	console.log('adding-ingredient');

	let _ingredient_name = $$('#ingredient-name').val();
	let _add_price = $$('#add-price').val().replace(',', '.');
	let _remove_price = 0; //$$('#remove-price').val().replace(',', '.');

	console.log('ing name ' + _ingredient_name);
	console.log('add price ' + _add_price);
	console.log('remove price ' + _remove_price);

	if(_ingredient_name == "")
	{
		f7app.dialog.alert('Inserire il nome della variante!', "Dati non coerenti");

		return;
	}

	var _request_params = 
	{
		'session_token' : appCore.session_token,
		'name': $$('#ingredient-name').val(),
		'description': $$('#ingredient-description').val(),
		'add_price': _add_price,
		'remove_price': _remove_price
	};

	f7app.request(
	{
		url: api_url + 'add_ingredient.php',
		contentType:  'multipart/form-data',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('added ingredient: ', data)

			appCore.clearCurrentEdit();

			ViewMenu.router.navigate("/variants/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});
});

$$(document).on('click', '#save-ingredient', function (e) 
{
	e.preventDefault();

	let _ingredient_name = $$('#ingredient-name').val();
	let _add_price = $$('#add-price').val().replace(',', '.');
	let _remove_price = 0; //$$('#remove-price').val().replace(',', '.');

	console.log('ing name ' + _ingredient_name);
	console.log('add price ' + _add_price);
	console.log('remove price ' + _remove_price);

	if(_ingredient_name == "")
	{
		f7app.dialog.alert('Inserire il nome della variante!', "Dati non coerenti");

		return;
	}

	console.log('prices: ', "+" + _add_price + ", -" + _remove_price + ", current edited id: " + appCore.current_edited_item.Id)

	var _request_params = 
	{
		'session_token' : appCore.session_token,
		'id': appCore.current_edited_item.id,
		'name': $$('#ingredient-name').val(),
		'description': $$('#ingredient-description').val(),
		'add_price': _add_price,
		'remove_price': _remove_price,
	};

	f7app.request(
	{
		url: api_url + 'edit_ingredient.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('saved ingredient: ', data);

			appCore.clearCurrentEdit();

			ViewMenu.router.navigate("/variants/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});
});

$$(document).on('click', '#exit-item', function (e) 
{
	appCore.clearCurrentEdit();
});

f7app.sheet.create({ el: '.cancel-confirm-sheet',
	swipeToClose: true,
	backdrop: true,
});

$$(document).on('click', '#cancel-confirm', function (e) 
{
	f7app.sheet.close('.cancel-confirm-sheet');

	var _request_params = 
	{
		'item_id' : appCore.current_edited_item.item_id,
		'session_token' : appCore.session_token
	};

	f7app.request(
	{
		url: api_url + 'delete_item.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('item deleted: ', data);

			appCore.clearCurrentEdit();

			ViewMenu.router.navigate("/items/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});

});

$$(document).on('click', '#cancel-ingredient-confirm', function (e) 
{
	f7app.sheet.close('.cancel-confirm-sheet');

	var _request_params = 
	{
		'id' : appCore.current_edited_item.id,
		'session_token' : appCore.session_token
	};

	f7app.request(
	{
		url: api_url + 'delete_ingredient.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('ingredient deleted: ', data);

			appCore.clearCurrentEdit();

			ViewMenu.router.navigate("/variants/",
			{
				ignoreCache: true,
				force: true
			});
		},
		error: function ()
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
		}
	});

});

$$(document).on('click', '#cancel-stop', function (e) 
{
	e.preventDefault();

	f7app.sheet.close('.cancel-confirm-sheet');
});

// --- Profile ---

$$(document).on('click', '#update-profile', function (e) 
{
  let _name = $$('#profile-name').val().trim();
  let _surname = $$('#profile-surname').val().trim();
  let _phoneNumber = $$('#profile-phone-number').val().trim();

  if(_name == "")
  {
    f7app.dialog.alert("Inserire un nome valido!", "Attenzione!");

    return;
  }

  if(_surname == "")
  {
    f7app.dialog.alert("Inserire un cognome valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber == "")
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber.length < 9)
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
      'name' : _name,
      'surname' : _surname,
      'phonenumber' : _phoneNumber,
	  'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'edit_customer_profile.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);
      let _msg = data.msg;

      f7app.dialog.alert(_msg);
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});

$$(document).on('input', '.filter', function (e) 
{
	e.preventDefault();

	let _periodFrom = (e.target.id == 'from-period') ? e.target.value : ViewProfile.$el.find('#from-period').val();
	let _periodTo = (e.target.id == 'to-period') ? e.target.value : ViewProfile.$el.find('#to-period').val();

	ViewProfile.router.navigate(
		"/profile/?periodFrom=" + _periodFrom + 
		"&periodTo=" + _periodTo,
		{
			ignoreCache: true,
			force: true
		}
	);
});

// --- Device and App data ---

function get_current_app_version()
{
  var _request_params = 
  {
    'app_type' : app_type
  };

  f7app.request(
  {
    url: api_url + 'current_app_version.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (currentAppVersionData) 
    {
      let _success = parseFloat(currentAppVersionData.success);

      console.log("current app version data", currentAppVersionData);

      if(_success == 1)
      {
        let _current_app_version = currentAppVersionData.data.app_version;
        let _last_update_version = appUtilities.getParam('last-update-version');

        if(_last_update_version == _current_app_version)
        {
            // E' già stata gestita questa versione

            return true;
        }

        if(_current_app_version != app_version)
        {
          // Salva in locale che si sta gestendo questa nuova versione

          appUtilities.setParam('last-update-version', _current_app_version);

          // Update app

          if
          (
            Framework7.device.android == true
            || Framework7.device.cordova == true
            || Framework7.device.ios == true
            || Framework7.device.ipad == true
            || Framework7.device.iphone == true
            || Framework7.device.ipod == true
            || Framework7.device.macos == true
            || Framework7.device.phonegap == true
            || Framework7.device.webView == true
            || Framework7.device.webview == true
          )
          {
            // App nativa

			/*
            alert(`
              È presente una versione piu recente dell'applicazione.
              Ti consigliamo di effettuare l'aggiornamento in quanto alcune funzionalità della versione corrente potrebbero non funzionare, Grazie.
              `, "Aggiornamento");
			*/
          }
          else
          {
            // App web

            alert(`
              E' presente un nuovo aggiornamento dell'applicazione. 
              Chiudendo questo messaggio verrà installato il nuovo aggiornamento e riavviata l'applicazione.
              `, "Aggiornamento");
        
            if ('serviceWorker' in navigator) 
            {
              // Cancella la cache dell'applicazione
  
              caches.keys().then(function(cacheNames) 
              {
                cacheNames.forEach(function(cacheName) 
                {
                  caches.delete(cacheName);
                });
              });
  
              // Ricarica la pagina
  
              window.location.href = app_url;
            }
            else
            {
              // Impossibile cancellare la cache
  
              alert("Si è verificato un errore durante l'aggiornamento: si prega di contattare il supporto tecnico.");
            }
          }
        }
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

function get_device_type()
{
  let _ret = '';

  if(Framework7.device.android == true)
  {
    _ret = 'android';
  }
  else if(Framework7.device.androidChrome == true)
  {
    _ret = 'android-chrome';
  }
  else if(Framework7.device.cordova == true)
  {
    _ret = 'cordova';
  }
  else if(Framework7.device.desktop == true)
  {
    _ret = 'desktop';
  }
  else if(Framework7.device.edge == true)
  {
    _ret = 'edge';
  }
  else if(Framework7.device.electron == true)
  {
    _ret = 'electron';
  }
  else if(Framework7.device.firefox == true)
  {
    _ret = 'firefox';
  }
  else if(Framework7.device.ie == true)
  {
    _ret = 'ie';
  }
  else if(Framework7.device.ios == true)
  {
    _ret = 'ios';
  }
  else if(Framework7.device.ipad == true)
  {
    _ret = 'ipad';
  }
  else if(Framework7.device.iphone == true)
  {
    _ret = 'iphone';
  }
  else if(Framework7.device.ipod == true)
  {
    _ret = 'ipod';
  }
  else if(Framework7.device.macos == true)
  {
    _ret = 'macos';
  }
  else if(Framework7.device.nwjs == true)
  {
    _ret = 'nwjs';
  }
  else if(Framework7.device.phonegap == true)
  {
    _ret = 'phonegap';
  }
  else if(Framework7.device.webView == true || Framework7.device.webView == true)
  {
    _ret = 'webview';
  }
  else if(Framework7.device.windows == true)
  {
    _ret = 'windows';
  }

  return _ret;
}

function send_app_and_device_data()
{
  var _request_params = 
  {
    'session_token' : appCore.session_token,
    'app_version' : app_version,
    'os_version' : Framework7.device.os,
    'device_type' : device_type
  };

  f7app.request(
  {
    url: api_url + 'device_app_data.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
    },
    error: function () 
    {
    }
  });
}