function AppCore(initCallback)
{
    let self = this;

    this.session_token = '';
	this.restaurant_data;

	this.waiting_orders_first_load = true;
	this.waiting_orders;
	this.waiting_orders_count = 0;

	this.accepted_orders_first_load = true;
	this.accepted_orders;
	this.accepted_orders_count = 0;
	
	this.past_orders;
	this.order_id;
	this.items = {};
	this.ingredients = {};
	this.cat_subcat;
	this.current_subcat = {};
	this.current_edited_item = {};

	if(typeof initCallback !== 'undefined')
    {
        window.appCore = self;
        
        initCallback();
    }

	this.clearCurrentEdit = function()
	{
		this.current_subcat = {};
		this.current_edited_item = {};
	}
}

export default AppCore;
