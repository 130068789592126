import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageInit: function () {
      let _user_logged = appUtilities.user_is_logged();

      if (_user_logged == false) {
        return false;
      }

      $$('.toolbar').show();
    }
  },
  id: '26c2cf56ef',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=menu><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div id=menu-title class=title>Menu</div><div class=right><a class=link href=/help/ >Aiuto</a></div></div></div><!-- Scrollable page content--><div class=page-content><div id=white-space-menu class=item-content style="height: 50px;"></div><a href=/items/ ><div class="row padding"><button class="col button button-small button-fill" style="min-height: 50px;">PRODOTTI</button></div></a><a href=/variants/ ><div class="row padding"><button class="col button button-small button-fill" style="min-height: 50px;">VARIANTI</button></div></a></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};