
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '9c3b088e4a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=profile><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>Profilo</div><div class=right><a id=logout href=# class=link>Esci</a></div></div></div><!-- Scrollable page content--><div class=page-content><div class=card><!-- Card body --><div class="card-content card-content-padding"><div class="list no-hairlines-md"><ul><!-- Nome --><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome</div><div class=item-input-wrap><input id=profile-name readonly=readonly type=text placeholder=Nome value="';
      r += Template7Helpers.js.call(ctx_1, 'this.profile.resturant_name', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"></div></div></li><!-- Telefono --><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">Telefono</div><div class=item-input-wrap><input id=profile-phone-number type=text placeholder=Telefono value=';
      r += c(ctx_1.profile.phonenumber, ctx_1);
      r += '> <span class=input-clear-button></span></div></div></li><!-- Email --><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">E-mail</div><div class=item-input-wrap><input type=email placeholder=Email value=';
      r += c(ctx_1.profile.email, ctx_1);
      r += '> <span class=input-clear-button></span></div></div></li></ul></div><!--\n					<button id="update-profile" class="col button button-large button-fill" style="min-height: 50px; margin-top: 20px;">Salva modifiche</button>\n					--></div></div><div class=block-title style="font-size: larger;"><b>Totale ordini nel periodo</b></div><div class=card><!-- Card body --><div class="card-content card-content-padding"><div class="list no-hairlines-md"><ul><!-- FILTRO DATA --><li><div class=item-content><span>Dal </span><span><input class=filter id=from-period type=date value=';
      r += c(ctx_1.period_from, ctx_1);
      r += ' style="border: 1px solid #b11733;"></span></div><div class=item-content><span>al </span><span><input class=filter id=to-period type=date value=';
      r += c(ctx_1.period_to, ctx_1);
      r += ' style="border: 1px solid #b11733;"></span></div></li><li><div class=item-content><div class=item-inner><b>Ordini: ';
      r += c(ctx_1.data.orders_count, ctx_1);
      r += '</b></div></div></li><li><div class=item-content><div class=item-inner><b>Contanti: ';
      r += c(ctx_1.data.cash_totals, ctx_1);
      r += '&euro;</b></div></div></li><li><div class=item-content><div class=item-inner><b>Carte di credito: ';
      r += c(ctx_1.data.cards_totals, ctx_1);
      r += '&euro;</b></div></div></li><li><div class=item-content><div class=item-inner><b>Totale: ';
      r += c(ctx_1.data.orders_totals, ctx_1);
      r += '&euro;</b></div></div></li></ul></div></div></div><div class=card><!-- Card body --><div class="card-content card-content-padding"><a href=/app-info/ >Info applicazione</a></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    