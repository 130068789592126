
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: 'e1f53e1905',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=variants><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><!--BACK TO ORDERS--><div class=left><a href=/menu/ ><i class="icon icon-back"></i> <span class=if-not-md></span></a></div><div class=title>Elenco varianti</div><div class=right><a class=link href=/help/ >Aiuto</a></div></div></div><!-- Floating Action Button --><div id=add-variant class="fab fab-right-bottom"><a href="/variant/?type=new"><i class="icon f7-icons">plus</i></a></div><!-- Scrollable page content--><div class=page-content><div class=card><div class="card-content card-content-padding"><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href="/variant/?type=edit&id=';
          r += c(ctx_2.id, ctx_2);
          r += '" class="item-link item-content"><div class=item-inner><div class=item-title-row><div class=item-title><span style="font-weight: bold; color: #b11733;">Nome:</span> <b>';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</b></div></div><div class=item-text></div><div class=item-row><div class=item-title><b>Descrizione:</b> ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.description, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>';
          r += Template7Helpers.js_if.call(ctx_2, "this.add_price > 0", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'Aggiunta: +';
              r += c(ctx_3.add_price, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</b></div><div class=item-title></div></div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div><div id=white-space-menu class=item-content style="height: 50px;"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    